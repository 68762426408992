import * as React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Page from "../elements/Page.js"
import Icon from "../elements/Icon.js"

import EmpresasParceiras from "../section/EmpresasParceiras.js"
import FormContatoImageSection from "../section/FormContatoImageSection.js"

import ContainerWithBg from "../helpers/ContainerWithBg.js"
import Container from "../helpers/Container.js"


export default function WorkshopPage(props){

  let { featuredImage, title, yoastData, content } = props.pageContext

  if(!featuredImage) featuredImage = { localFile: "../../images/post-image.png", altText: "Placeholder Image"}
  const image = getImage(featuredImage.localFile)

  const icones = content.comunicacaoWorkshopConteudoIcones
  const paraQuem = content.comunicacaoWorkshopConteudoParaQuem
  const lider = content.comunicacaoWorkshopConteudoLider
  const porque = content.comunicacaoWorkshopConteudoPorque
  const programa = content.comunicacaoWorkshopConteudoPrograma

  let programaSrc = programa.comunicacaoWorkshopConteudoProgramaImagem

  if(!programaSrc) programaSrc = { localFile: "../../images/post-image.png", altText: "Placeholder Image"}
  const programaImage = getImage(programaSrc.localFile)

  return (
    <Page>
      <Seo 
        title={yoastData.title}
        post={yoastData} 
      />

      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-dynamic"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1"
        style={{backgroundImage: `url(${image.images.fallback.src}`}}
      >
        <span className="tag bg-color-golden c-purple m-2 c-p db ttu mb-2 bold">
          Workshop
        </span>
        <h1 className="h1 mb-0">{title}</h1>
      </ContainerWithBg>

      <Container className="container-fluid">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-4 p-lg-8">
            <div className="col-size-720">
              {/* Descrição */}
              <div dangerouslySetInnerHTML={{ 
                  __html: content.comunicacaoWorkshopConteudoDescricao
                }} 
                className="pb-4" 
              />

              {/* metodologia */}
              <h2 className="h2 c-purple">Metodologia</h2>
              <div dangerouslySetInnerHTML={{ 
                  __html: content.comunicacaoWorkshopConteudoMetodologia
                }} 
                className="list-default" 
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0 bg-color-purple-4 d-flex flex-column justify-content-center p-4 p-lg-8">
            <div className="col-size-720">
              {/* ParaQuem */}
                <h2 className="h2 c-purple title-left-arrow ms-8 ms-lg-0 mb-3">{paraQuem.comunicacaoWorkshopConteudoParaQuemTitulo}</h2>
                <div dangerouslySetInnerHTML={{ 
                    __html: paraQuem.comunicacaoWorkshopConteudoParaQuemConteudo
                  }} 
                  className="list-default" 
                />
                
                {/* lider */}
                <h2 className="h2 c-purple title-left-arrow ms-8 ms-lg-0 mb-3">{lider.comunicacaoWorkshopConteudoLiderTitulo}</h2>
                <div dangerouslySetInnerHTML={{ 
                    __html: lider.comunicacaoWorkshopConteudoLiderConteudo
                  }} 
                  className="list-default" 
                />

                {/* porque */}
                <h2 className="h2 c-purple title-left-arrow ms-8 ms-lg-0 mb-3">{porque.comunicacaoWorkshopConteudoPorqueTitulo}</h2>
                <div dangerouslySetInnerHTML={{ 
                    __html: porque.comunicacaoWorkshopConteudoPorqueConteudo
                  }} 
                  className="list-default" 
                />
            </div>
          </div>
        </div>
      </Container>

      {/* Icones */}
      <Container className="py-6 sep-y">
        <div className="container">
            <div className="row align-items-center justify-content-center">
              { icones &&
                icones.map((icone, index) => (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={`icon-${index}`}>
                    <Icon
                      type={icone.comunicacaoWorkshopConteudoIconesIcone}
                      content={icone.comunicacaoWorkshopConteudoIconesConteudo}
                    />
                  </div>
                ))
              }
            </div>
        </div>
      </Container>

      <Container className="container-fluid">
        <div className="row">
          <div className="col-md-6 order-md-2 bg-color-golden">
            <div className="col-size-720 p-4 p-lg-8">
              {/* Programa */}
              <h2 className="h2 c-purple title-left-arrow ms-8 ms-lg-0">{programa.comunicacaoWorkshopConteudoProgramaTitulo}</h2>
              <div dangerouslySetInnerHTML={{ 
                  __html: programa.comunicacaoWorkshopConteudoProgramaConteudo
                }} 
                className="list-default fs-body-large" 
              />
            </div>
          </div>
          <div className="col-md-6 order-md-1 d-flex p-0">
            <GatsbyImage image={programaImage} className="w-100 mh-27r" objectFit="cover" alt={programaSrc.altText} />
          </div>
        </div>
      </Container>
      <EmpresasParceiras />
      <FormContatoImageSection />
    </Page>
  )
}